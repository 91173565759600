<template>
  <div>
    <v-layout wrap justify-center>
      <v-snackbar v-model="showSnackBar" color="black">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: white">
              {{ msg }}
            </span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#13736f"
        spinner="spinner" />
      <v-flex xs12 text-center>
        <v-layout wrap pr-1 pt-8 justify-center>
          <v-flex xs11 lg3 pb-4 text-left>
            <v-layout wrap class="mainfont">
              <v-flex v-if="product.name" xs12 pb-2 style="
                    font-family: dmsansregular;
                    font-size: 15px;
                    color: black;
                    font-weight: 700;
                  ">{{ product.name }}
              </v-flex>
              <v-flex xs12>
                <v-card elevation="0" class="text-center" color="#FAFAFA">
                  <v-layout fill-height wrap justify-center pa-3>
                    <!-- <v-flex xs12 pa-2 v-if="product.photos && product.photos.length > 0" :style="{ height: '480px' }"
                      class="d-flex align-center">
                      <v-img :src="mediaURL + product.photos[0]"></v-img>
                    </v-flex> -->
                    <template>
                      <v-layout fill-height wrap justify-center pa-3>
                        <v-flex xs12 pa-2 v-if="product.photos && product.photos.length > 0" class="d-flex align-center">
                          <v-dialog max-width="600px">
                            <template v-slot:activator="{ on }">
                              <v-img v-on="on" :src="mediaURL + product.photos[0]"></v-img>
                            </template>
                            <v-card>
                              <v-img :src="mediaURL + product.photos[0]" :width="500"></v-img>
                            </v-card>
                          </v-dialog>
                        </v-flex>
                      </v-layout>
                    </template>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs11 lg2 pt-8>
            <v-layout wrap justify-center>
              <v-flex pb-3 v-for="(item, i) in product.photos" :key="i" xs11 lg9>
                <v-card class="text-center" elevation="0" color="#FAFAFA">
                  <v-layout fill-height wrap justify-center pa-3>
                    <center>
                      <v-flex xs12 :style="{ height: '90px' }" class="d-flex align-center">
                        <v-avatar size="80px" class="mx-auto">
                          <v-img :src="mediaURL + item"></v-img>
                        </v-avatar>
                      </v-flex>
                    </center>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs11 lg4 text-left pt-7>
            <v-layout wrap>
              <v-flex xs12>
                <span style="
                    font-family: dmsansregular;
                    font-size: 18px;
                    color: black;
                    font-weight: 600;
                  "> {{ product.name }}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;<v-icon @click="addtoWishlist2(product._id)" v-if="!product.inWishlist"
                  color="black" size="200%">mdi-bookmark-outline</v-icon>
                <v-icon @click="addtoWishlist2(product._id)" v-else color="#E96125" size="200%">mdi-bookmark</v-icon>
              </v-flex>
              <v-flex v-if="product.stock > 0" pt-2 xs12 style="
                    font-family: dmsansregular;
                    font-size: 14px;
                    color: #18ff59;
                    font-weight: 500;
                  ">In Stock</v-flex>
              <v-flex v-else pt-2 xs12 style="
                    font-family: dmsansregular;
                    font-size: 14px;
                    color: red;
                    font-weight: 500;
                  ">Out of Stock</v-flex>
              <v-flex v-if="!product.inCart" pt-2 xs11 lg12 style="
                    font-family: dmsansregular;
                    font-size: 22px;
                    color: #29807c;
                    font-weight: 700;
                  ">Rs {{ product.price }}</v-flex>
              <v-flex v-else pt-2 xs11 lg12 style="
                    font-family: dmsansregular;
                    font-size: 22px;
                    color: #29807c;
                    font-weight: 700;
                  ">Rs {{ product.price * product.quantityInCart }}</v-flex>
              <v-flex pt-2 xs12 style="
                    font-family: dmsansregular;
                    font-size: 18px;
                    color: black;
                    font-weight: 600;
                  ">Weight : {{ product.weight }} {{ product.weightType }}</v-flex>
              <v-flex pt-2 xs12 style="
                    font-family: dmsansregular;
                    font-size: 16px;
                    color: black;
                    font-weight: 600;
                  ">Description</v-flex>
              <v-flex pt-2 xs11 style="
                    font-family: dmsansregular;
                    font-size: 14px;
                    color: #2b2b2b;
                    font-weight: 400;
                  ">{{ product.description }}</v-flex>
              <v-flex xs11 pt-4>
                <v-divider color="black"></v-divider>
              </v-flex>
              <v-flex v-if="product.stock > 0" xs6 pt-4 text-left>
                <v-btn v-if="!product.inCart" elevation="0" rounded class="no-uppercase" width="151px" height="45px"
                  color="#F2F2F2">
                  <v-icon @click="decrementQuantity" left>mdi-minus</v-icon>
                  <span style="
        font-family: opensansregular;
        font-size: 14px;
        color: black;
        letter-spacing: 1px;
        cursor: pointer;
      ">&nbsp;{{ initialcount }}&nbsp;</span>
                  <v-icon :disabled="initialcount >= product.stock" @click="incrementQuantity" right>mdi-plus</v-icon>
                </v-btn>
                <v-btn v-else elevation="0" rounded class="no-uppercase" width="151px" height="45px" color="#F2F2F2">
                  <v-icon @click="decrementQuantity" left>mdi-minus</v-icon>
                  <span style="
        font-family: opensansregular;
        font-size: 14px;
        color: black;
        letter-spacing: 1px;
        cursor: pointer;
      ">&nbsp;{{ product.quantityInCart }}&nbsp;</span>
                  <v-icon :disabled="product.quantityInCart >= product.stock" @click="incrementQuantity"
                    right>mdi-plus</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs6 v-else pt-4 text-left>
                <v-btn disabled v-if="!product.inCart" elevation="0" rounded class="no-uppercase" width="151px"
                  height="45px" color="#F2F2F2">
                  <v-icon @click="decrementQuantity" left>mdi-minus</v-icon>
                  <span disabled style="
        font-family: opensansregular;
        font-size: 14px;
        color: black;
        letter-spacing: 1px;
        cursor: pointer;
      ">&nbsp;{{ initialcount }}&nbsp;</span>
                  <v-icon :disabled="initialcount >= product.stock" @click="incrementQuantity" right>mdi-plus</v-icon>
                </v-btn>
                <v-btn v-else elevation="0" rounded class="no-uppercase" width="151px" height="45px" color="#F2F2F2">
                  <v-icon @click="decrementQuantity" left>mdi-minus</v-icon>
                  <span style="
        font-family: opensansregular;
        font-size: 14px;
        color: black;
        letter-spacing: 1px;
        cursor: pointer;
      ">&nbsp;{{ product.quantityInCart }}&nbsp;</span>
                  <v-icon :disabled="product.quantityInCart >= product.stock" @click="incrementQuantity"
                    right>mdi-plus</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs6 pt-5 text-left v-if="!product.inCart">
                <v-btn v-if="product.stock > 0" @click="addtoCart2()" class="no-uppercase" width="180px"
                  color="#E96125"><span style="
                        font-family: opensansregular;
                        font-size: 14px;
                        color: #ffffff;
                        letter-spacing: 1px;
                        cursor: pointer;
                      ">&nbsp;Add to Cart &nbsp;
                  </span>
                </v-btn>
                <v-btn v-else disabled class="no-uppercase" width="180px" color="#E96125"><span style="
                        font-family: opensansregular;
                        font-size: 14px;
                        color: #ffffff;
                        letter-spacing: 1px;
                        cursor: pointer;
                      ">&nbsp;Add to Cart &nbsp;
                  </span>
                </v-btn>
              </v-flex>
              <v-flex xs6 pt-5 text-left v-else>
                <v-btn v-if="product.stock > 0" @click="$router.push('/cart')" class="no-uppercase" width="180px"
                  color="#E96125"><span style="
                        font-family: opensansregular;
                        font-size: 14px;
                        color: #ffffff;
                        letter-spacing: 1px;
                        cursor: pointer;
                      ">&nbsp;Go to Cart &nbsp;
                  </span>
                </v-btn>
                <v-btn v-else disabled class="no-uppercase" width="180px" color="#E96125"><span style="
                        font-family: opensansregular;
                        font-size: 14px;
                        color: #ffffff;
                        letter-spacing: 1px;
                        cursor: pointer;
                      ">&nbsp;Go to Cart &nbsp;
                  </span>
                </v-btn>
              </v-flex>
              <v-flex xs11 pt-5>
                <v-card height="90px" elevation="0" style="
                      border-top: 1px solid black;
                      border-left: 1px solid black;
                      border-right: 1px solid black;
                      border-radius: 0;
                    ">
                  <v-layout wrap pt-5 justify-center>
                    <v-flex xs2 pl-7>
                      <template>
                        <v-img src="../../assets/Images/CreditCard.png"></v-img>
                      </template>
                    </v-flex>
                    <v-flex xs10 pl-8 pb-3 text-left>
                      <span style="
            font-family: dmsansregular;
            font-size: 16px;
            color: black;
            font-weight: 600;
          ">
                        Secure Payments
                      </span>
                      <br />
                      <span style="
            font-family: dmsansregular;
            font-size: 14px;
            color: black;
            font-weight: 500;
          ">
                        Fast and secured payment options.
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
              <v-flex xs11 pb-5>
                <v-card height="90px" elevation="0" style="
                      border-top: 1px solid black;
                      border-left: 1px solid black;
                      border-right: 1px solid black;
                      border-top: 1px solid black;
                      border-bottom: 1px solid black;
                      border-radius: 0;
                    ">
                  <v-layout wrap pt-5 justify-center>
                    <v-flex xs2 pl-7>
                      <template>
                        <v-img src="../../assets/Images/ThumbsUp2.png"></v-img>
                      </template>
                    </v-flex>
                    <v-flex xs10 pl-8 pb-3 text-left>
                      <span style="
                            font-family: dmsansregular;
                            font-size: 16px;
                            color: black;
                            font-weight: 600;
                          ">
                        High Quality Products
                      </span>
                      <br />
                      <span style="
                            font-family: dmsansregular;
                            font-size: 14px;
                            color: black;
                            font-weight: 500;
                          ">
                        Best Quality product available in the market.
                      </span>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex pl-9 style="
                font-family: opensansregular;
                color: black;
                font-size: 30px;
                font-weight: bold;  
              " xs11 pa-4 text-left>Related Items</v-flex>
          <v-flex v-if="list.length>0" xs12>
            <v-layout wrap justify-center pl-8>
              <v-flex xs11>
                <v-layout wrap justify-center>
                  <v-flex pb-2 xs8 sm5 lg3 v-for="(item, i) in list" :key="i">
                    <v-card elevation="0" outlined width="270px">
                      <v-layout wrap justify-center pa-3>
                        <center>
                          <v-flex class="hidden-sm-and-down" pl-8 xs4 style="cursor: pointer;"
                            @click="$router.push('/products?id=' + item._id)">
                            <v-avatar size="90px">
                              <v-img :src="mediaURLnew + item.photos[0]"></v-img>
                            </v-avatar>
                          </v-flex>
                        </center>
                        <v-flex pb-2 class="hidden-md-and-up" pl-7 xs4 style="cursor: pointer;"
                          @click="$router.push('/products?id=' + item._id)">
                          <v-avatar size="90px">
                            <v-img :src="mediaURLnew + item.photos[0]"></v-img>
                          </v-avatar>
                        </v-flex>
                        <v-flex v-if="!item.inWishlist" xs5 text-right @click="addtoWishlist(item._id)" style="
        cursor: pointer;
      ">
                          <v-icon color="black" size="200%">mdi-bookmark-outline</v-icon>
                        </v-flex>
                        <v-flex style="
        cursor: pointer;
      " v-else xs5 text-right @click="addtoWishlist(item._id)">
                          <v-icon color="#E96125" size="200%">mdi-bookmark</v-icon>
                        </v-flex>

                        <v-flex @click="$router.push('/products?id=' + item._id)" pt-3 text-center xs12
                          style="color: #BCB4B4;cursor: pointer; font-size: 12px;" class="opensansregular">
                          {{ item.categoryId.name }}
                        </v-flex>

                        <v-flex pt-1 text-center xs12 class="dmsansregular"
                          style="color: black; font-size: 20px;cursor: pointer; font-weight: 500">
                          {{ item.name }}
                        </v-flex>

                        <v-flex @click="$router.push('/products?id=' + item._id)" pt-1 text-center xs12
                          class="dmsansregular" style="color: black;cursor: pointer; font-size: 14px; font-weight: 600">
                          {{ item.weight }} {{ item.weightType }}
                        </v-flex>
                        <v-flex pt-1 text-center xs12 class="dmsansregular"
                          style="color: #29807c; font-size: 20px; font-weight: 700">
                          Rs {{ item.price }}
                        </v-flex>
                        <v-flex v-if="!item.inCart" pt-1 text-center xs12>
                          <v-btn v-if="item.stock > 0" @click="addtoCart(item._id)" class="no-uppercase" width="110px"
                            color="#13736f"><span style="
                              font-family: opensansregular;
                              font-size: 14px;
                              color: #FFFFFF;
                              letter-spacing: 1px;
                              cursor: pointer;
                            ">&nbsp;Add to Cart &nbsp;
                            </span>
                          </v-btn>
                          <v-btn v-else disabled class="no-uppercase" width="110px" color="#13736f"><span style="
                              font-family: opensansregular;
                              font-size: 14px;
                              color: red;
                              letter-spacing: 1px;
                              cursor: pointer;
                            ">&nbsp;Out of Stock &nbsp;
                            </span>
                          </v-btn>
                        </v-flex>
                        <v-flex v-else pt-1 text-center xs12>
                          <v-btn @click="$router.push('/cart')" class="no-uppercase" width="110px" color="#13736f"><span
                              style="
                              font-family: opensansregular;
                              font-size: 14px;
                              color: #FFFFFF;
                              letter-spacing: 1px;
                              cursor: pointer;
                            ">&nbsp;Go to Cart &nbsp;
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                  <div v-if="cartAddSuccess" class="success-message">
                    Product added to cart successfully!
                  </div>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout pt-9 pb-6 wrap justify-center>
              <v-flex align-self-center>
                <div>
                  <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages" :total-visible="7"
                    v-model="currentPage" color="#13736f"></v-pagination>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex v-else xs12 pb-10 text-center>
            <span style="
                font-family: opensansregular;
                color: black;
                font-size: 20px;
                font-weight: bold;  
              ">No Related Items Found!</span> 
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  
<script>
import axios from "axios";
export default {
  data() {
    return {
      currentPage: 1,
      product: [],
      id: this.$route.query.id,
      initialcount: 1,
      pages: 0,
      preview: null,
      link: null,
      prev: null,
      page: 1,
      showSnackBar: false,
      msg: null,
      limit: 10,
      url: null,
      categoryName: null,
      cartAddSuccess: false,
      appLoading: false,
      name: null,
      description: null,
      duration: null,
      icon: null,
      formData: new FormData(),
      formData2: new FormData(),
      listingOrder: null,
      list: [],
      editdailog: false,
      curid: [],
      searchKeyword: null,
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      amount: null,
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
    this.getProduct();

  },
  watch: {
    currentPage() {
      this.getList();
    },
    categoryId() {
      this.getList();
    },
    status() {
      this.getList();
    },
  },
  methods: {
    addtoWishlist2(productId) {
      axios({
        method: "POST",
        url: "/wishlist/add/remove",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          productId: productId,
          wishlistType: "Product",
        },
      })
        .then((response) => {
          location.reload();
          if (response.data.status === true) {
            // Wishlist updated successfully, handle any UI changes
          } else {
            console.log("Adding to wishlist failed: " + response.data.msg);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    incrementQuantity() {
      if (this.product.quantityInCart < this.product.stock) {
        this.product.quantityInCart++;
        this.editCart(this.product.quantityInCart);
      }
      if (this.initialcount) {
        this.initialcount++
      }
    },
    decrementQuantity() {
      if (this.product.quantityInCart > 1) {
        this.product.quantityInCart--;
        this.editCart(this.product.quantityInCart);
      }
      if (this.initialcount > 1) {
        this.initialcount--;
      }
    },
    addtoCart2() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/cart/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          quantity: this.initialcount,
          productId: this.product._id,

        },
      })
        .then((response) => {
          this.pages = Math.ceil(this.totalData / response.data.limit);
          location.reload();
          this.appLoading = false;

        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    editCart(updatedQuantity) {
      axios({
        method: "POST",
        url: "/cart/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.product._id,
          quantity: updatedQuantity,
        },
      })
        .then((response) => {
          if (response.data.status === true) {
            // Cart edited successfully, you can handle any UI update here
          } else {
            console.log("Editing cart failed: " + response.data.msg);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    addtoCart(productId) {
      this.appLoading = true;
      this.cartAddSuccess = false;

      axios({
        method: "POST",
        url: "/cart/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          productId: productId,
          quantity: this.quantity,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data === true) {
            this.cartAddSuccess = true;
          }
          location.reload();
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },



    getProduct() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/view/" + this.id,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.product = response.data.data;
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    addtoWishlist(productId) {
      axios({
        method: "POST",
        url: "/wishlist/add/remove",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          productId: productId,
          wishlistType: "Product",
        },
      })
        .then((response) => {
          location.reload();
          if (response.data.status === true) {
            // Wishlist updated successfully, handle any UI changes
          } else {
            console.log("Adding to wishlist failed: " + response.data.msg);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/store/top/product/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          limit: 4,
          excludeId:this.id,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>